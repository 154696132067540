import React, { Component } from "react"
import { Link } from "gatsby"

import StaticBG from "../../assets/images/pages/region-hero.jpg"

import regionsByState from "../../assets/data/regionsByState_2022"
import RegionFinder from "./region-finder"

export default class Regions extends Component {
  
  render() {
    return (
      <div id="region">
        
  <RegionFinder
          handleCountryChange={this.props.handleCountryChange}
          handleZipChange={this.props.handleZipChange}
          handleInterestChange={this.props.handleInterestChange}
          handleSubmit={this.props.handleSubmit}
          noZip={this.props.noZip}
          interest={this.props.interest}
        /> 

        <section id="region-hero">
          <div
            className="image"
            // style={{ backgroundImage: "url(" + StaticBG + ")" }}
          ></div>
        </section>
        <section id="region-page">
          <div className="page-pad">
            <div className="page">
              <div className="column-single">
                <h2>Regional Partners</h2>
                <section id="region-partner-join" className="box">
                  <h4>Become a Partner</h4>
                  <p>
                    The Alliance seeks to forge partnerships with local
                    organizations to expand student and educator access to the
                    Awards. If you are interested in learning more about
                    becoming an Affiliate Partner in your region, please click
                    the ‘learn more’ button below.
                  </p>
                  <div id="region-partner-button">
                    <Link to="/the-alliance/partners/become-a-partner/" className="button">
                      Learn More
                    </Link>
                  </div>
                </section>
                <section id="region-partner-list">
                  {regionsByState.map((state, index) => (
                    <div key={index} className="state">
                      <div className="state-name">{state.stateName}</div>
                      <div className="state-partners">
                        {state.regions.map((region, index) => (
                          <p key={index}>
                            <strong>
                              <Link to={`/regions/${region.shortCode}`}>{region.name}</Link>
                            </strong>
                            {region.organizationName}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </section>
              </div>
            </div>
          </div>
        </section>

        {this.props.children}
      </div>
    )
  }
}