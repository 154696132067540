import React  from "react"
import { Link } from "gatsby"

export default props => {
  const { handleCountryChange, handleZipChange, handleInterestChange, handleSubmit, noZip } = props

  return (
    <section id="region-form">
      <form onSubmit={handleSubmit}>
        <div id="region-form-heading">
          <span>Find my region</span>
        </div>
        <div id="region-form-help">
          <Link to="/frequently-asked-questions/" title="Help">
            ?
          </Link>
        </div>
        <div id="region-form-fields">

        <div className="form-country">
            <label hidden htmlFor="country">
              Country</label>
              <select name="country" onChange={handleCountryChange}>
              <option value="none" defaultValue disabled hidden>Select Country</option>
                <option value="USA">United States</option>
                <option value="CAN">Canada</option>
              </select>

          </div>
          <div className="form-item">
            <label htmlFor="zip">School's zip code </label>
            <input
              required
              type="text"
              name="zip"
              id="zip"
              pattern="\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d"
              onChange={handleZipChange}
            />
          </div>

          <div className="form-interest">
            <label htmlFor="interest">
              Area of interest</label>
              <select name="interest" onChange={handleInterestChange}>
                <option value="0">Art & Writing</option>
                <option value="0">Art</option>
                <option value="1">Writing</option>
              </select>
            
          </div>

          {noZip ? (
            <div className="note noZip">
              <p>Sorry, this zip code is not mapped.</p> Please try again or <Link to="/contact">get in touch.</Link>
            </div>
          ) : (
            <div className="note">
              Your school zip may differ from your home zip code
            </div>
          )}
        </div>
        <div id="region-form-submit">
          <input type="submit" value="Find Region" />
        </div>
      </form>
    </section>
  )
}