// 2022-23 JSON!!
export default [
  {
    stateName: "Alabama",
    regions: [
      {
        shortCode: "AL001W",
        name: "Alabama Writing Region",
        organizationName: "Wiregrass Writing Project",
      },
    ],
  },
  {
    stateName: "Alaska",
    regions: [
      {
        shortCode: "AK001A",
        name: "Alaska Art Region",
        organizationName: "Young Emerging Artists, Inc.",
      },
    ],
  },
  {
    stateName: "Arkansas",
    regions: [
      {
        shortCode: "ARMS1A",
        name: "Mid-South Art Region",
        organizationName: "Memphis Brooks Museum of Art",
      },
    ],
  },
  {
    stateName: "California",
    regions: [
      {
        shortCode: "CA003A",
        name: "Northern California Art Region",
        organizationName: "Yuba Sutter Arts & Culture",
      },
      {
        shortCode: "CA005A",
        name: "California Bay Area Art Region",
        organizationName: "California College of the Arts",
      },
      {
        shortCode: "CA001W",
        name: "The Los Angeles Writing Region",
        organizationName: "The Alliance for Young Artists & Writers",
      },

      {
        shortCode: "CA003W",
        name: "Northern California Writing Region",
        organizationName: "Yuba Sutter Arts & Culture",
      },
    ],
  },
  {
    stateName: "Colorado",
    regions: [
      {
        shortCode: "CO001A",
        name: "Colorado Art Region",
        organizationName: "Colorado Art Awards Inc.",
      },
    ],
  },
  {
    stateName: "Connecticut",
    regions: [
      {
        shortCode: "CT001A",
        name: "Connecticut Art Region",
        organizationName: "Connecticut Art Education Association",
      },
      {
        shortCode: "CT001W",
        name: "Connecticut Writing Region",
        organizationName: "University of Connecticut",
      },
      {
        shortCode: "NY002W",
        name: "Hudson-to-Housatonic Writing Region",
        organizationName: "Writopia Lab",
      },
    ],
  },
  {
    stateName: "District of Columbia",
    regions: [
      {
        shortCode: "DC001W",
        name: "D.C. Metro Writing Region",
        organizationName: "Writopia Lab",
      },
    ],
  },
  {
    stateName: "Delaware",
    regions: [
      {
        shortCode: "DE001A",
        name: "Delaware Art Region",
        organizationName: "Arts Center / Gallery at Delaware State University",
      },
      {
        shortCode: "DE001W",
        name: "Delaware Writing Region",
        organizationName:
          "Diamond State Branch, National League of American Pen Women, Inc.",
      },
    ],
  },
  {
    stateName: "Florida",
    regions: [
      {
        shortCode: "FL004A",
        name: "Pinellas County Art Region",
        organizationName: "Pinellas County Schools: PreK-12 Visual Arts Department",
      },
      {
        shortCode: "FL005A",
        name: "Palm Beach and Martin Counties Art Region",
        organizationName: "The Armory Art Center",
      },
      {
        shortCode: "FL007A",
        name: "Northeast Florida Art Region",
        organizationName: "Northeast Florida Art Education Association",
      },
      {
        shortCode: "FL002A",
        name: "Miami-Dade Art Region",
        organizationName: "Miami-Dade County Public Schools",
      },
      {
        shortCode: "FL003A",
        name: "Sarasota Art Region",
        organizationName: "Sarasota County Schools",
      },
      {
        shortCode: "FL006A",
        name: "Broward County Art Region",
        organizationName: "Young at Art Museum",
      },
      {
        shortCode: "FL008A",
        name: "Hillsborough County Art Region",
        organizationName: "Hillsborough County Public Schools",
      },
      {
        shortCode: "FL009A",
        name: "Polk County Art Region",
        organizationName: "Polk Museum of Art at Florida Southern College",
      },
      {
        shortCode: "FL006W",
        name: "Broward County Writing Region",
        organizationName: "Young at Art Museum",
      },
      {
        shortCode: "FL008W",
        name: "Hillsborough County Writing Region",
        organizationName: "Hillsborough County Public Schools",
      },
    ],
  },
  {
    stateName: "Georgia",
    regions: [
      {
        shortCode: "GA001A",
        name: "Georgia Art Region",
        organizationName: "Savannah College of Art and Design",
      },
      {
        shortCode: "GA001W",
        name: "Georgia Writing Region",
        organizationName: "Savannah College of Art and Design",
      },
    ],
  },
  {
    stateName: "Hawaii",
    regions: [
      {
        shortCode: "HI001A",
        name: "Hawaii Art Region",
        organizationName: "Hawaii State Foundation on Culture and the Arts",
      },
    ],
  },
  {
    stateName: "Iowa",
    regions: [
      {
        shortCode: "IA001A",
        name: "Iowa Multi-State Art Region",
        organizationName:
          "Belin-Blank Center for Gifted Education and Talent Development",
      },
      {
        shortCode: "IA001W",
        name: "Iowa Multi-State Writing Region",
        organizationName:
          "Belin-Blank Center for Gifted Education and Talent Development",
      },
    ],
  },
  {
    stateName: "Idaho",
    regions: [
      {
        shortCode: "ID001W",
        name: "Idaho Writing Region",
        organizationName: "Boise State Writing Project at Boise State University",
      },
    ],
  },
    {
    "stateName": "Illinois",
    "regions": [
      {
        "shortCode": "IL001A",
        "name": "Chicago Art Region",
        "organizationName": "Columbia College Chicago"
      },
      {
        "shortCode": "IL001W",
        "name": "Chicago Writing Region",
        "organizationName": "Writopia Lab"
      },
      {
        "shortCode": "IL005A",
        "name": "Suburban Chicago Art Region",
        "organizationName": "Downers Grove North and South High Schools"
      },
      {
        "shortCode": "IL006A",
        "name": "Mid-Central Illinois Art Region",
        "organizationName": "Mid-Central Illinois Art Region"
      },
      {
        "shortCode": "IL007A",
        "name": "Southern Illinois Art Region",
        "organizationName": "John R. and Eleanor R. Mitchell Foundation / Cedarhurst Center for the Arts"
      },
    ]
  },

  {
    stateName: "Indiana",
    regions: [
      {
        shortCode: "IN001A",
        name: "Northeast Indiana and Northwest Ohio Art Region",
        organizationName: "The Fort Wayne Museum of Art",
      },
      {
        shortCode: "INMI1A",
        name: "Northwest Indiana and Lower Southwest Michigan Art Region",
        organizationName: "South Bend Museum of Art",
      },
      {
        shortCode: "IN002A",
        name: "Central & Southern Indiana Art Region",
        organizationName: "Hoosier Writing Project at IUPUI",
      },
      {
        shortCode: "IN001W",
        name: "Northeast Indiana and Northwest Ohio Writing Region",
        organizationName: "The Fort Wayne Museum of Art",
      },
      {
        shortCode: "IN002W",
        name: "Central & Southern Indiana Writing Region",
        organizationName: "Hoosier Writing Project at IUPUI",
      },
      {
        shortCode: "OH001A",
        name: "Southern Ohio, Northern Kentucky, and Southeastern Indiana Art Region",
        organizationName: "Art Academy of Cincinnati",
      },
    ],
  },
  {
      "stateName": "Kansas",
    "regions": [
      {
        "shortCode": "KS001A",
        "name": "Eastern Kansas Art Region",
        "organizationName": "Mark Arts"
      },
      {
        "shortCode": "KS002A",
        "name": "Western Kansas Art Region",
        "organizationName": "The Western Kansas Scholastic Art Awards"
      }
    ]
  },
    {
    "stateName": "Kentucky",
    "regions": [
      {
        "shortCode": "KY004A",
        "name": "Louisville Metropolitan Area Art Region",
        "organizationName": "Jefferson County Public Schools"
      },
      {
        "shortCode": "KY003A",
        "name": "South Central Kentucky Art Region",
        "organizationName": "Jerry E. Baker Foundation"
      },
      {
        "shortCode": "KY002W",
        "name": "Eastern Kentucky Writing Region",
        "organizationName": "Morehead Writing Project"
      },
      {
        shortCode: "OH001A",
        name: "Southern Ohio, Northern Kentucky, and Southeastern Indiana Art Region",
        organizationName: "Art Academy of Cincinnati",
      },
      {
        shortCode: "OH001W",
        name: "Southern Ohio and Northern Kentucky Writing Region",
        organizationName: "Art Academy of Cincinnati",
      },
    ]
  },
  {
    stateName: "Massachusetts",
    regions: [
      {
        shortCode: "MA001A",
        name: "Massachusetts Art Region",
        organizationName:
          "The School of the Museum of Fine Arts at Tufts University",
      },
      {
        shortCode: "MA001W",
        name: "Massachusetts Writing Region",
        organizationName:
          "The School of the Museum of Fine Arts at Tufts University",
      },
    ],
  },
  {
    stateName: "Maryland",
    regions: [
      {
        shortCode: "MD001A",
        name: "Maryland Art Region",
        organizationName: "Baltimore Office of Promotions and the Arts",
      },
      {
        shortCode: "MD001W",
        name: "Maryland Writing Region",
        organizationName: "Baltimore Office of Promotions and the Arts",
      },
    ],
  },
  {
    stateName: "Maine",
    regions: [
      {
        shortCode: "ME002A",
        name: "Maine Art Region",
        organizationName: "Maine College of Art and Design",
      },
    ],
  },
    {
    "stateName": "Michigan",
    "regions": [
      {
        "shortCode": "MI004A",
        "name": "Southeast Michigan and Thumb Art Region",
        "organizationName": "College for Creative Studies"
      },
      {
        "shortCode": "MI003A",
        "name": "West Central Michigan Art Region",
        "organizationName": "Kendall College of Art and Design of Ferris State University and The Howard Miller Company"
      },
      {
        "shortCode": "MI003W",
        "name": "West Central Michigan Writing Region",
        "organizationName": "Kendall College of Art and Design of Ferris State University and The Howard Miller Company"
      },
      {
        shortCode: "INMI1A",
        name: "Northwest Indiana and Lower Southwest Michigan Art Region",
        organizationName: "South Bend Museum of Art",
      },
      {
        "shortCode": "KY004A",
        "name": "Louisville Metropolitan Area Art Region",
        "organizationName": "Jefferson County Public Schools"
      },
    ]
  },
  {
    stateName: "Minnesota",
    regions: [
      {
        shortCode: "MN001A",
        name: "Minnesota Art Region",
        organizationName: "Art Educators of Minnesota",
      },
      {
        shortCode: "MN001W",
        name: "Minnesota Writing Region",
        organizationName: "Minnesota Writing Project",
      },
    ],
  },
  {
    stateName: "Missouri",
    regions: [
      {
        shortCode: "MO001W",
        name: "Missouri Writing Region",
        organizationName: "Greater Kansas City Writing Project",
      },
    ],
  },
  {
    stateName: "Mississippi",
    regions: [
      {
        shortCode: "MS001A",
        name: "Mississippi Art Region",
        organizationName: "Mississippi Museum of Art",
      },
      {
        shortCode: "MS001W",
        name: "Mississippi Writing Region",
        organizationName: "Eudora Welty Foundation",
      },
    ],
  },
  {
    stateName: "North Carolina",
    regions: [
      {
        shortCode: "NC001A",
        name: "Western North Carolina Art Region",
        organizationName: "Asheville Art Museum",
      },
      {
        shortCode: "NC002A",
        name: "Eastern North Carolina Art Region",
        organizationName: "East Carolina University",
      },
      {
        shortCode: "NC003A",
        name: "Mid-Carolina Art Region",
        organizationName: "The College of Arts + Architecture at the University of North Carolina at Charlotte",
      },
    ],
  },
  {
    stateName: "North Dakota",
    regions: [
      {
        shortCode: "ND001A",
        name: "North Dakota Art Region",
        organizationName: "Plains Art Museum",
      },
      {
        shortCode: "ND001W",
        name: "North Dakota Writing Region",
        organizationName:
          "The Red River Valley Writing Project at NDSU",
      },
    ],
  },
  {
    "stateName": "Nebraska",
    "regions": [
      {
        "shortCode": "NE001W",
        "name": "Nebraska Writing Region",
        "organizationName": "Hastings College"
      },
      {
        "shortCode": "NE001A",
        "name": "Nebraska Art Region",
        "organizationName": "Omaha Public Schools Art Department"
      },
    ]
  },
  {
    stateName: "New Hampshire",
    regions: [
      {
        shortCode: "NH001A",
        name: "New Hampshire Art Region",
        organizationName: "The Scholastic Art Awards of New Hampshire",
      },
      {
        shortCode: "NH001W",
        name: "New Hampshire Writing Region",
        organizationName: "The National Writing Project in New Hampshire",
      },
    ],
  },
  {
    stateName: "New Jersey",
    regions: [
      {
        shortCode: "NJ001A",
        name: "Northern New Jersey Art Region",
        organizationName: "Gallery Aferro",
      },
      {
        shortCode: "NJ002A",
        name: "South Jersey Art Region",
        organizationName: "Appel Farm Arts & Music Campus",
      },
    ],
  },
  {
    stateName: "New Mexico",
    regions: [
      {
        shortCode: "NM001A",
        name: "New Mexico Art Region",
        organizationName: "New Mexico Art Education Association",
      },
    ],
  },
  {
    stateName: "Nevada",
    regions: [
      {
        shortCode: "NV001A",
        name: "Northern Nevada Art Region",
        organizationName: "Nevada Museum of Art",
      },
    ],
  },
  {
    stateName: "New York",
    regions: [
      {
        shortCode: "NY003A",
        name: "Central New York Art Region",
        organizationName: "CNY Art Council",
      },
      {
        shortCode: "NY002A",
        name: "Hudson Valley Art Region",
        organizationName: "Hudson Valley Art Awards",
      },
      {
        shortCode: "NYPA1A",
        name: "Twin Tiers Art Region",
        organizationName: "Arnot Art Museum",
      },
      {
        shortCode: "NY004A",
        name: "Western New York Art Region",
        organizationName: "Alfred University",
      },
      {
        shortCode: "NY001A",
        name: "New York City Art Region",
        organizationName: "Alliance for Young Artists & Writers",
      },

      {
        shortCode: "NY001W",
        name: "New York City Writing Region",
        organizationName: "Alliance for Young Artists & Writers",
      },
      {
        shortCode: "NY002W",
        name: "Hudson-to-Housatonic Writing Region",
        organizationName: "Writopia Lab",
      },
    ],
  },
  {
    stateName: "Ohio",
    regions: [
      {
        shortCode: "OH003A",
        name: "Central Ohio Art Region",
        organizationName: "Columbus College of Art & Design",
      },
      {
        shortCode: "OH005A",
        name: "Lorain County Art Region",
        organizationName: "Lorain County Region Scholastic Arts Committee",
      },
      {
        shortCode: "OH007A",
        name: "Northeastern Ohio Art Region",
        organizationName: "Youngstown State University",
      },
      {
        shortCode: "OH002A",
        name: "Greater Cleveland Art Region",
        organizationName: "The Cleveland Institute of Art",
      },
      {
        shortCode: "OH004A",
        name: "Northeast Central Ohio Art Region",
        organizationName: "Kent State University at Stark",
      },
      {
        shortCode: "OH001A",
        name: "Southern Ohio, Northern Kentucky, and Southeastern Indiana Art Region",
        organizationName: "Art Academy of Cincinnati",
      },
      {
        shortCode: "OH008A",
        name: "Miami Valley Art Region",
        organizationName: "K12 Gallery & TEJAS",
      },
      {
        shortCode: "OH004W",
        name: "Northeast Central Ohio Writing Region",
        organizationName: "Kent State University at Stark",
      },
      {
        shortCode: "OH001W",
        name: "Southern Ohio and Northern Kentucky Writing Region",
        organizationName: "Art Academy of Cincinnati",
      },
      {
        shortCode: "OH002W",
        name: "Greater Cleveland Writing Region",
        organizationName: "The Cleveland Institute of Art",
      },
      {
        shortCode: "IN001A",
        name: "Northeast Indiana and Northwest Ohio Art Region",
        organizationName: "The Fort Wayne Museum of Art",
      },
      {
        shortCode: "IN001W",
        name: "Northeast Indiana and Northwest Ohio Writing Region",
        organizationName: "The Fort Wayne Museum of Art",
      },
    ],
  },
  {
    stateName: "Pennsylvania",
    regions: [
      {
        shortCode: "PA008A",
        name: "East Central Pennsylvania Art Region",
        organizationName: "Kutztown University",
      },
      {
        shortCode: "PA002A",
        name: "Pittsburgh Arts Region",
        organizationName: "The Seneca Valley School District",
      },
      {
        shortCode: "PA001A",
        name: "Philadelphia Art Region",
        organizationName: "Philadelphia Arts in Education Partnership",
      },
      {
        shortCode: "PA007A",
        name: "Northeastern Pennsylvania Art Region",
        organizationName: "Everhart Museum of Natural History, Science and Art",
      },
      {
        shortCode: "PA009A",
        name: "Southwestern Pennsylvania Art Region",
        organizationName: "PennWest California University",
      },
      {
        shortCode: "PA003A",
        name: "Lancaster County Art Region",
        organizationName: "Lancaster Museum of Art",
      },
      {
        shortCode: "PA004A",
        name: "South Central Pennsylvania Art Region",
        organizationName: "Commonwealth Charter Academy",
      },
      {
        shortCode: "PA009W",
        name: "Southwestern Pennsylvania Writing Region",
        organizationName: "PennWest California University",
      },
      {
        shortCode: "PA001W",
        name: "Philadelphia Writing Region",
        organizationName: "Philadelphia Writing Project",
      },
      {
        shortCode: "PA004W",
        name: "South Central Pennsylvania Writing Region",
        organizationName: "Commonwealth Charter Academy",
      },
      {
        shortCode: "PA005W",
        name: "Pittsburgh Writing Region",
        organizationName:
          "Point Park University",
      },
      {
        shortCode: "NYPA1A",
        name: "Twin Tiers Art Region",
        organizationName: "Arnot Art Museum",
      },
    ],
  },
    {
    "stateName": "South Carolina",
    "regions": [
      {
        "shortCode": "SC001W",
        "name": "South Carolina Lowcountry Writing Region",
        "organizationName": "Savannah College of Art and Design"
      },
      {
        "shortCode": "SC001A",
        "name": "South Carolina Lowcountry Art Region",
        "organizationName": "Savannah College of Art and Design"
      },
    ]
  },
  {
    stateName: "South Dakota",
    regions: [
      {
        shortCode: "SD001A",
        name: "South Dakota Art Region",
        organizationName: "The University of South Dakota",
      },
      {
        shortCode: "SD001W",
        name: "South Dakota Writing Region",
        organizationName: "The University of South Dakota",
      },
    ],
  },
  {
    stateName: "Tennessee",
    regions: [
      // {
      //   shortCode: "ARMS1A",
      //   name: "Mid-South Art Region",
      //   organizationName: "Memphis Brooks Museum of Art",
      // },
      {
        shortCode: "TN002A",
        name: "Middle Tennessee Art Region",
        organizationName: "Cheekwood",
      },
    ],
  },
    {
    "stateName": "Texas",
    "regions": [
      {
        "shortCode": "TX002A",
        "name": "Austin Texas Art Region",
        "organizationName": "St. Stephen’s Episcopal School"
      },

      {
        "shortCode": "TX001A",
        "name": "Harris County Art Region",
        "organizationName": "Harris County Department of Education"
      },
      {
        "shortCode": "TX001W",
        "name": "Harris County Writing Region",
        "organizationName": "Harris County Department of Education"
      },
      {
        "shortCode": "TX004A",
        "name": "Greater San Antonio Art Region",
        "organizationName": "North East Independent School District (NEISD)"
      },
      {
        "shortCode": "TX003A",
        "name": "West Texas Art Region",
        "organizationName": "Wayland Baptist University and the Abraham Family Art Gallery"
      },
      {
        "shortCode": "TX004W",
        "name": "Greater San Antonio Writing Region",
        "organizationName": "North East Independent School District (NEISD)"
      }
    ]
  },

  {
    stateName: "Virginia",
    regions: [
      {
        shortCode: "VA002A",
        name: "Fairfax County Art Region",
        organizationName: "Fairfax County Public Schools",
      },
      {
        shortCode: "VA003A",
        name: "Arlington County Art Region",
        organizationName: "Arlington County Public Schools",
      },
      {
        shortCode: "VA004A",
        name: "Southwest Virginia Art Region",
        organizationName: "The Fine Arts Center for the New River Valley",
      },
      {
        shortCode: "VA005A",
        name: "Richmond Art Region",
        organizationName: "Visual Arts Center of Richmond",
      },
      {
        shortCode: "VA005W",
        name: "Richmond Writing Region",
        organizationName: "Visual Arts Center of Richmond",
      },
    ],
  },
  {
    stateName: "Vermont",
    regions: [
      {
        shortCode: "VT001A",
        name: "Vermont Art Region",
        organizationName: "Brattleboro Museum & Art Center",
      },
      {
        shortCode: "VT001W",
        name: "Vermont Writing Region",
        organizationName: "Brattleboro Museum & Art Center",
      },
    ],
  },
  {
    stateName: "Washington",
    regions: [
      {
        shortCode: "WA001A",
        name: "Snohomish County Art Region",
        organizationName: "Schack Art Center",
      },
    ],
  },
  {
    stateName: "Wisconsin",
    regions: [
      {
        shortCode: "WI001A",
        name: "Wisconsin Art Region",
        organizationName: "Milwaukee Art Museum",
      },
      {
        shortCode: "WI002W",
        name: "Southeast Wisconsin Scholastic Writing Region",
        organizationName: "Southeast Wisconsin Scholastic Writing Region",
      },
    ],
  },
  {
    stateName: "Canada",
    regions: [
      {
        shortCode: "BC001A",
        name: "Lower Mainland British Columbia Art Region",
        organizationName: "Art Village Visual Art Studio"
      },
      {
        shortCode: "CN001A",
        name: "Region-at-Large Canada Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "CN001W",
        name: "Region-at-Large Canada Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "U.S. Territories",
    regions: [],
  },
  {
    stateName: "Armed Forces (the) Americas",
    regions: [
      {
        shortCode: "ARALSW",
        name: "Region-at-Large South Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALSW",
        name: "Region-at-Large South Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Armed Forces Europe",
    regions: [
      {
        shortCode: "ARALNE",
        name: "Region-at-Large East Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },

      {
        shortCode: "WRALNE",
        name: "Region-at-Large East Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Armed Forces Pacific",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "American Samoa",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Micronesia",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Guam",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Marshall Islands",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Northern Mariana Islands",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Puerto Rico",
    regions: [
      {
        shortCode: "ARALSW",
        name: "Region-at-Large South Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },

      {
        shortCode: "WRALSW",
        name: "Region-at-Large South Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "Palau",
    regions: [
      {
        shortCode: "ARALW",
        name: "Region-at-Large West Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALW",
        name: "Region-at-Large West Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
  {
    stateName: "U.S. Virgin Islands",
    regions: [
      {
        shortCode: "ARALSW",
        name: "Region-at-Large South Art",
        organizationName: "The Alliance for Young Artists & Writers",
      },
      {
        shortCode: "WRALSW",
        name: "Region-at-Large South Writing",
        organizationName: "The Alliance for Young Artists & Writers",
      },
    ],
  },
]